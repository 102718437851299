import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontSize, space, color } from 'styled-system';

const DefinitionList = styled.dl`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  ${space}
`;

DefinitionList.defaultProps = {
  my: {
    xsm: 3,
    lg: 4,
  },
  mx: 0,
  px: {
    xsm: 2,
    lg: 4,
  },
};

const Field = styled.dt`
  flex-direction: column;
  ${fontSize}
  ${color}
`;

Field.defaultProps = {
  color: 'gray',
  fontSize: {
    xsm: 1,
    md: 2,
    lg: 3,
  },
};

const Value = styled.dd`
  font-variant-numeric: tabular-nums;
  ${fontSize}
  ${space}
`;

Value.defaultProps = {
  fontSize: {
    xsm: 7,
    md: 8,
    lg: 12,
  },
  mt: {
    xsm: 1,
    md: 2,
  },
  mx: 0,
};

const CountdownItem = ({ title, children }) => (
  <DefinitionList>
    <Field>{title}</Field>
    <Value>{children}</Value>
  </DefinitionList>
);

CountdownItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default CountdownItem;
