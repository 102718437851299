import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
//import Header from 'components/header';
import GlobalStyle from 'global.css.js';
import { Main } from './layout.css';
import { ThemeProvider } from 'styled-components';
import designTokens from './../../designTokens.js';

const Layout = ({ children }) => (
  <Main>
    <GlobalStyle />
    <Head />
    <ThemeProvider theme={designTokens}>{children}</ThemeProvider>
  </Main>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
