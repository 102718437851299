export const space = [0, 4, 8, 16, 32, 64, 128];

const mainTypeface = 'Montserrat';
const fallbackTypeface = 'Helvetica Neue';
const fontStack = 'Helvetica,Arial,sans-serif';

export const font = `${mainTypeface}, ${fallbackTypeface}, ${fontStack}`;

export const fontSizes = [
  12,
  14,
  16,
  20,
  24,
  32,
  40,
  64,
  72,
  96,
  120,
  144,
  160,
];

export const breakpoints = {
  xsm: 0, //Mobile-first
  sm: '400px',
  md: '600px',
  lg: '900px',
  xl: '1200px',
  xxl: '1600px',
};

export const medium = 500;
export const bold = 700;
// alias
export const regular = medium;

// styled-system's `fontWeight` function can hook into the `fontWeights` object
export const fontWeights = {
  medium,
  bold,
  // alias
  regular,
};

export const lineHeights = {
  standard: 1.5,
  display: 1.25,
};

const letterSpacings = {
  normal: 'normal',
  caps: '0.025em',
};

export const textStyles = {
  display8: {
    fontSize: fontSizes[8] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display7: {
    fontSize: fontSizes[7] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display6: {
    fontSize: fontSizes[6] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display5: {
    fontSize: fontSizes[5] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display4: {
    fontSize: fontSizes[4] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display3: {
    fontSize: fontSizes[3] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display2: {
    fontSize: fontSizes[2] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display1: {
    fontSize: fontSizes[1] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
  },
  display0: {
    fontSize: fontSizes[0] + 'px',
    fontWeight: fontWeights.bold,
    lineHeight: lineHeights.display,
    letterSpacing: letterSpacings.caps,
    textTransform: 'uppercase',
  },
  body2: {
    fontSize: fontSizes[2] + 'px',
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.standard,
  },
  body1: {
    fontSize: fontSizes[1] + 'px',
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.standard,
  },
  body0: {
    fontSize: fontSizes[0] + 'px',
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.standard,
  },
};

// color palette
const black = '#444';
const white = '#fff';
const text = '#001833';
const lightBlue = '#e8f2ff';
const blue = '#007aff';
const darkBlue = '#049';
const lightGray = '#f4f6f8';
const borderGray = '#c0cad5';
const gray = '#4f6f8f';
const darkGray = '#555555';
const lightGreen = '#ecf7ec';
const green = '#0a0';
const darkGreen = '#060';
const lightRed = '#fbebeb';
const red = '#c00';
const darkRed = '#800';
const orange = '#f68013';
const darkOrange = '#f06f20';
const lightPurple = '#f5ebfa';
const purple = '#70b';
const yellow = '#fedc2a';
const lightYellow = '#fff3c0';

const colors = {
  black,
  white,
  text,
  blue,
  lightBlue,
  darkBlue,
  gray,
  lightGray,
  borderGray,
  darkGray,
  green,
  lightGreen,
  darkGreen,
  red,
  lightRed,
  darkRed,
  orange,
  darkOrange,
  purple,
  lightPurple,
  yellow,
  lightYellow,
};

export { colors };

// styled-system's `borderRadius` function can hook into the `radii` object/array
export const radii = [0, 2, 6];
export const radius = '2px';

export const maxContainerWidth = '1280px';

// animation duration
export const duration = {
  fast: '150ms',
  normal: '300ms',
  slow: '450ms',
  slowest: '600ms',
};

// animation easing curves
const easeInOut = 'cubic-bezier(0.5, 0, 0.25, 1)';
const easeOut = 'cubic-bezier(0, 0, 0.25, 1)';
const easeIn = 'cubic-bezier(0.5, 0, 1, 1)';

const timingFunctions = {
  easeInOut,
  easeOut,
  easeIn,
};

// animation delay
const transitionDelays = {
  small: '60ms',
  medium: '160ms',
  large: '260ms',
  xLarge: '360ms',
};

const designTokens = {
  breakpoints,
  space,
  font,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  regular,
  bold,
  textStyles,
  colors,
  radii,
  radius,
  maxContainerWidth,
  duration,
  timingFunctions,
  transitionDelays,
};

export default designTokens;
