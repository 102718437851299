import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown-now';
import CountdownItem from './countdownItem';
import {
  CountdownContainer,
  CountdownItemContainer,
  Title,
} from './countdown.css';

// Renderer callback with condition
const renderCountdown = ({
  props,
  days,
  hours,
  minutes,
  seconds,
  completed,
}) => {
  if (completed) {
    // Render a completed state
    return <CountdownContainer>Finished!</CountdownContainer>;
  } else {
    // Render a countdown
    return (
      <CountdownContainer>
        {props.title && <Title>{props.title}</Title>}
        <CountdownItemContainer>
          <CountdownItem title="Days:">{days}</CountdownItem>
          <CountdownItem title="Hours:">{hours}</CountdownItem>
          <CountdownItem title="Minutes:">{minutes}</CountdownItem>
          <CountdownItem title="Seconds:">{seconds}</CountdownItem>
        </CountdownItemContainer>
      </CountdownContainer>
    );
  }
};

const StyledCountdown = props => {
  return <Countdown {...props} renderer={renderCountdown} />;
};

StyledCountdown.propTypes = {
  title: PropTypes.string,
};

export default StyledCountdown;
