import styled from 'styled-components';
import { fontSize, color, flexDirection } from 'styled-system';

const CountdownContainer = styled.section`
  text-align: center;
  width: 100%;
`;

const CountdownItemContainer = styled.section`
  ${color}
  display: flex;
  ${flexDirection}
  text-align: center;
  font-weight: normal;
`;

CountdownItemContainer.defaultProps = {
  color: 'black',
  flexDirection: {
    xsm: 'column',
    md: 'row',
  },
};

const Title = styled.h2`
  ${color}
  ${fontSize}
  font-weight: normal;
`;

Title.defaultProps = {
  color: 'darkGray',
  fontSize: {
    xsm: 2,
    md: 3,
    lg: 5,
  },
};

export { CountdownContainer, Title, CountdownItemContainer };
